import React from "react";
import styles from "./HeroSection.module.scss";
 
 
import DownIcon from "../SVGs/DownIcon";
export default function HeroSlide({ img, headerText, description, explore }) {
  return (
    <div className={` ${styles.heroSlide}`}>
      <img src={img} loading="lazy" alt={headerText} />
      <div className={`container ${styles.heroTextWrapper}`}>
        <h2>{headerText}</h2>
        <p>{description}</p>
        <a href={"#explore"}>
          <DownIcon /> {explore}
        </a>
      </div>
    </div>
  );
}
