import React from "react";
import styles from "./WhyUs.module.scss";
import WhyUsCard from "./WhyUsCard";
import { useTranslation } from "react-i18next";

function WhyUs({ whyChooseUs }) {
  // STATES
  // STORE STATES
  // HOOKS
  const {t} = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <section className={styles.whyUs}>
      <h2 className={styles.whyUsTitle}>{t("about.whyus")}</h2>
      <div className={styles.whyUsCardContainer}>
        {whyChooseUs?.map((card, index) => {
          return <WhyUsCard card={card} key={index} />;
        })}
      </div>
    </section>
  );
}
export default WhyUs;
