import React from "react";
import styles from "./notFoundPage.module.scss";
import assets from "./assets";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "./components/Navbar";

function NotFound({ props }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useParams();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <>
      <Navbar />
      <div className={styles.notFound}>
        <img
          className={styles.notFoundImage}
          src={assets.images.notFound}
          alt="not found"
        />
        <div className={styles.notFoundContent}>
          <h2 className={styles.notFoundContentTitle}>{t("not_found")}</h2>
          <p className={styles.notFoundContentBody}>{t("not_found_desc")}</p>
          <button
            onClick={() => {
              if (locale) navigate(`/${locale}/`);
              else navigate("/");
            }}
            className={styles.notFoundButton}
          >
            {t("go_to_home")}
          </button>
        </div>
      </div>
    </>
  );
}
export default NotFound;
