import React, { forwardRef } from "react";
import styles from "./TextInput.module.scss";

import assets from "../../../assets";
function TextInput(
  {
    fieldName,
    label,
    type,
    validations,
    error,
    fullWidth,
    watchingResume,
    ...props
  },
  ref
) {
  if (type === "file") {
    console.log("watching resume", watchingResume);
    const file = watchingResume;
    const getIcon = (file) => {
      const fileName = file?.[0]?.name;
      let defaultIcon = assets.icons.attachIcon;
      if (fileName) {
        const fileExtention = fileName.split(".").reverse()[0];
        switch (fileExtention) {
          case "pdf":
            defaultIcon = assets.icons.pdfIcon;
            break;
          case "doc":
            defaultIcon = assets.icons.textIcon;
            break;
          case "docx":
            defaultIcon = assets.icons.textIcon;
            break;
          default:
            defaultIcon = assets.icons.attachIcon;
        }
      }
      return defaultIcon;
    };
    return (
      <div
        className={`${styles.inputControl} ${
          fullWidth ? styles.fullWidth : ""
        }`}
      >
        <div className={`${styles.inputWrapper} ${styles.fileInput}`}>
          <label htmlFor={fieldName}>
            <div className={styles.iconWrapper}>
              <img src={getIcon(file)} alt={label} width={20} height={20} />
            </div>
            {file ? file[0].name : label}
          </label>
          <input
            type={type}
            name={fieldName}
            id={fieldName}
            placeholder={label}
            {...props}
            ref={ref}
          />
        </div>
        {error ? <p className={styles.validationMessage}>{error}</p> : null}
      </div>
    );
  }
  if (type === "textarea") {
    return (
      <div className={`${fullWidth ? styles.fullWidth : ""}`}>
        <div className={styles.inputWrapper}>
          <textarea
            name={fieldName}
            id={fieldName}
            placeholder={label}
            {...props}
            ref={ref}
            rows={5}
          ></textarea>
        </div>
        {error ? <p className={styles.validationMessage}>{error}</p> : null}
      </div>
    );
  }
  return (
    <div className={`${fullWidth ? styles.fullWidth : ""}`}>
      <div className={styles.inputWrapper}>
        <input
          type={type}
          name={fieldName}
          id={fieldName}
          placeholder={label}
          {...props}
          ref={ref}
        />
      </div>
      {error ? <p className={styles.validationMessage}>{error}</p> : null}
    </div>
  );
}

export default forwardRef(TextInput);
