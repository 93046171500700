import FormSection from "./components/FormSection";
import styles from "./homePage.module.scss";
import WhatWeDo from "./components/WhatWeDo";

import assets from "./assets";

import ProjectsSection from "./components/ProjectsSection";
import About from "./components/About";
import HeroSection from "./components/HeroSection";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getHome } from "./utils/getHome";
import LoadingScreen from "./components/LoadingScreen";
import { useRef } from "react";



export default function Home() {
  const [loading, setLoading] = useState(false);
  const [home, setHome] = useState(null);
  const [data, setData] = useState(null);
  const { i18n, t } = useTranslation();
  const { locale } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();
  const exploreRef = useRef();

  const formContent = {
    title: t("home.contactus"),
    description: t("home.contactUsDesc"),
  };

  const fields = [
    {
      fieldName: "first_name",
      label: t("labels.firstname"),
      type: "text",
      validations: {
        required: t("validations.required"),
        minLength: { value: 2, message: t("validations.shortLength") },
        maxLength: { value: 30, message: t("validations.tooLong") },
      },
    },
    {
      fieldName: "last_name",
      label: t("labels.lastname"),
      type: "text",
      validations: {
        required: t("validations.required"),
        minLength: { value: 2, message: t("validations.shortLength") },
        maxLength: { value: 30, message: t("validations.tooLong") },
      },
    },
    {
      fieldName: "email",
      label: t("labels.email"),
      type: "email",
      validations: {
        required: "This Field is required",
        pattern: {
          value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          message: "Invalid Email",
        },
        maxLength: { value: 100, message: t("validations.tooLong") },
      },
    },
    {
      fieldName: "phone",
      label: t("labels.phonenumber"),
      type: "number",
      validations: {
        required: t("validations.required"),
        pattern: {
          value: /^(01)[0-9]{9}$/,
          message: t("validations.invalidPhonenumber"),
        },
        maxLength: { value: 11, message: t("validations.tooLong") },
      },
    },
    {
      fieldName: "message",
      label: t("labels.message"),
      type: "textarea",
      fullWidth: true,
      validations: {
        maxLength: {
          value: 1000,
          message: t("validations.tooLong"),
        },
      },
    },
  ];

  useEffect(() => {
    if (location.hash === "#contactus") {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
    if (location.hash === "#explore") {
      exploreRef.current?.scrollIntoView({ behavior: "smooth" });
      navigate(`/${locale}/`);
    }
  }, [location, exploreRef?.current, ref?.current]);

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setHome(null);
      const res = await getHome(locale);
      console.log("res", res);
      setHome(res);
      setData(res.company_info[0]);
      setLoading(false);
    }
    startFetch();
  }, [locale]);

  if (loading) {
    return <LoadingScreen />;
  }

  const socialMedia = [
    {
      type: "facebook",
      link: home?.company_info?.[0].facebook,
    },
    {
      type: "linkedIn",
      link: home?.company_info?.[0].linkedin,
    },
    {
      type: "instgram",
      link: home?.company_info?.[0].instagram,
    },
  ];

  return (
    <>
      <Navbar light={true} />
      <main className={styles.main}>
        <HeroSection slides={home?.main_section} />
        <About about={home?.about_us} info={home?.steel_info} />
        <WhatWeDo content={home?.what_we_do} />
        <div ref={exploreRef} id="explore"></div>
        <ProjectsSection projects={home?.projects} />
        <div ref={ref} id="contactus"></div>
        <FormSection content={formContent} formFields={fields}>
          <div className={styles.formInfo}>
            <h2 className={styles.formInfoTitle}>{t("home.info")}</h2>
            <div className={styles.formInfoEmail}>
              <img
                className={styles.formInfoImage}
                src={assets.icons.emailIcon}
                alt=""
              />
              <p className={styles.formInfoBody}>
                {home?.company_info?.[0].email}
              </p>
            </div>
            <div className={styles.formInfoPhone}>
              <img
                className={styles.formInfoImage}
                src={assets.icons.phoneIcon}
                alt=""
              />
              <p className={styles.formInfoBody}>
                {home?.company_info?.[0].phone}
              </p>
            </div>
            <div className={styles.formInfoPhone}>
              <img
                className={styles.formInfoImage}
                src={assets.icons.phoneIcon}
                alt=""
              />
              <p className={styles.formInfoBody}>
                {home?.company_info?.[0].phone2}
              </p>
            </div>
            <div className={styles.formInfoAddress}>
              <img
                className={styles.formInfoImage}
                src={assets.icons.addressIcon}
                alt=""
              />
              <p className={styles.formInfoBody}>
                {home?.company_info?.[0].address}
              </p>
            </div>
            <div className={styles.formInfoSocial}>
              {socialMedia.map((social, index) => {
                return (
                  <a key={index} href={social.link} target="_blank">
                    <img src={assets.icons[social.type]} alt={social.type} />
                  </a>
                );
              })}
            </div>
          </div>
        </FormSection>
      </main>
      <Footer data={data} />
    </>
  );
}
