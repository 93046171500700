import projectImg from "./card.jpg";
import aboutHero from "./aboutHero.png";
import heroImage from "./heroImage.png";
import projectImgDetails from "./image 3.png";
import approach from "./approach_bg.png";
import notFound from "./404.jpg";

const images = {
  projectImg,
  aboutHero,
  heroImage,
  projectImgDetails,
  approach,
  notFound,
};

export default images;
