import { useState, useEffect } from "react";
import WhoWeAre from "./components/WhoWeAre";
import styles from "./aboutPage.module.scss";
import WhyUs from "./components/WhyUs";
import OurApproach from "./components/OurApproach";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAbout } from "./utils/getAbout";
import LoadingScreen from "./components/LoadingScreen";

export default function AboutPage() {
  const [loading, setLoading] = useState(false);
  const [about, setAbout] = useState(null);
  const [data, setData] = useState(null);
  const { i18n } = useTranslation();
  const { locale } = useParams();

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setAbout(null);
      const res = await getAbout(locale);
      setAbout(res.about);
      setData(res.company_info[0]);
      setLoading(false);
    }
    startFetch();
  }, [locale]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Navbar />
      <main className={styles.main}>
        <WhoWeAre
          content={{
            title: about?.title,
            body: about?.body,
            story_title: about?.story_title,
            story_body: about?.story_body,
            video: about?.video,
            poster: about?.thumbnail_video,
          }}
        />
        <WhyUs whyChooseUs={about?.why_choose_us} />
        <OurApproach approaches={about?.approaches} />
      </main>
      <Footer data={data} />
    </>
  );
}
