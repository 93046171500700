import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Carousel.module.scss";
import CarouselPaging from "./CarouselPaging";
import { useTranslation } from "react-i18next";

export default function Carousel({
  children,
  whiteDot,
  end,
  sliderSettings,
  autoPlay = true,
  autoPlaySpeed = 3000,
}) {
  const { i18n } = useTranslation();

  const settings = {
    dots: true,
    autoplay: autoPlay,
    autoplaySpeed: autoPlaySpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: i18n.language === "ar" ? true : false,
    infinite: true,
    customPaging: (i) => {
      return (
        <a>
          <CarouselPaging />
        </a>
      );
    },
    dotsClass: `slick-dots ${whiteDot ? "active-dot-white" : ""} ${
      styles.dotsWrapper
    } ${end ? styles.end : ""}`,
    ...sliderSettings,
  };
  return (
    <div className={`slider`}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
}
