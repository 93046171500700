import React from "react";
import styles from "./WhyUsCard.module.scss";

function WhyUsCard({ card }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img src={card.icon} alt={card.title} />
      </div>
      <div className={styles.cardContent}>
        <h4 className={styles.cardTitle}>{card.title}</h4>
        <p className={styles.cardBody}>{card.body}</p>
      </div>
    </div>
  );
}
export default WhyUsCard;
