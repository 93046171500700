import facebookIcon from "./facebook.svg";
import facebook from "./facebook_blue.svg";
import twitterIcon from "./twitter.svg";
import twitter from "./twitter_blue.svg";
import linkedInIcon from "./linkedin.svg";
import linkedIn from "./linkedin_blue.svg";
import instgramIcon from "./instagram.svg";
import instgram from "./instagram_blue.svg";
import addressIcon from "./address.svg";
import emailIcon from "./email.svg";
import phoneIcon from "./phone.svg";
import playIcon from "./playIcon.svg";
import affordable from "./affordable.svg";
import problem from "./problem.svg";
import quality from "./quality.svg";
import quick from "./quick.svg";
import tailored from "./tailored.svg";
import universal from "./universal.svg";
import filterIcon from "./filter.svg";
import listArrow from "./list-arrow.svg";
const icons = {
  facebook,
  facebookIcon,
  twitter,
  twitterIcon,
  linkedIn,
  linkedInIcon,
  instgram,
  instgramIcon,
  addressIcon,
  emailIcon,
  phoneIcon,
  playIcon,
  affordable,
  problem,
  quality,
  quick,
  tailored,
  universal,
  filterIcon,
  listArrow,
};

export default icons;
