import React, { useState } from "react";
import styles from "./index.module.scss";
import LanguageSwitcher from "../../LanguageSwitcher";

import Logo from "../../SVGs/Logo";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HamburgerMenu({ light }) {
  const { t } = useTranslation();
  // VARIABLES
  const tabs = [
    {
      title: t("common.about"),
      link: "about",
    },
    {
      title: t("common.projects"),
      link: "projects",
    },
    {
      title: t("home.contactus"),
      link: "#contactus",
    },
  ];

  // STATES
  const [toogleMenu, setToogleMenu] = useState(false);
  // STORE STATES
  // HOOKS
  const { locale } = useParams();
  // METHODS
  const toogle = () => {
    if (!toogleMenu) {
      disableScroll();
    } else {
      enableScroll();
    }
    setToogleMenu((prev) => !prev);
  };

  function disableScroll() {
    // Get the current page scroll position
    const scrollTop = window.screenTop || document.documentElement.scrollTop;
    const scrollLeft = window.screenLeft || document.documentElement.scrollLeft;

    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  function enableScroll() {
    window.onscroll = function () {};
  }
  // EFFECTS
  // RENDER
  return (
    <>
      <nav
        className={`${styles.responsiveNav} ${
          light ? styles.light : undefined
        }`}
      >
        <a
          className={`${styles.responsiveNavLogo} ${
            toogleMenu ? styles.black : undefined
          }`}
          href={"/"}
        >
          <Logo />
        </a>
        <div onClick={toogle} className={styles.hamburgerMenu}>
          <p
            className={`${styles.line} ${
              toogleMenu ? styles.lineHide : undefined
            }`}
          ></p>
        </div>
      </nav>
      <div
        className={`${styles.responsiveMenu} ${
          toogleMenu ? styles.responsiveMenuAnimation : undefined
        }`}
      >
        <ul className={styles.responsiveMenuList}>
          {tabs.map((tab, index) => (
            <li key={index} className={styles.responsiveMenuListItem}>
              {<a href={`/${locale}/${tab.link}`}>{tab.title}</a>}
            </li>
          ))}
          <LanguageSwitcher />
        </ul>
      </div>
    </>
  );
}
export default HamburgerMenu;
