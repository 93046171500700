export const getProjectFilters = async (locale) => {
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/get_project_search_attributes`,
    {
      headers: {
        "Accept-Language": locale,
      },
    }
  );
  return res.json();
};

export const getProjects = async (locale, data, pageNum) => {
  if (data) {
    const body = {};
    if (data.area?.length) {
      Object.assign(body, {
        area: data.area.map((area) => area.value),
      });
    }
    if (data.service?.length) {
      Object.assign(body, {
        service: data.service.map((service) => service.value),
      });
    }
    if (data.type && data.type !== "all") {
      Object.assign(body, {
        type: data.type,
      });
    }
    
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/projects${pageNum ? `?page=${pageNum}`:""}`, {
      method: "POST",
      headers: {
        "Accept-Language": locale,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    return res.json();
  } else {
    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/projects${pageNum ? `?page=${pageNum}`:""}`, {
      method: "POST",
      headers: {
        "Accept-Language": locale,
      },
    });
    return res.json();
  }
};

export const getProject = async (locale, id) => {
  const body = {
    project_id: id,
  };
  const res = await fetch(`${process.env.REACT_APP_BASE_URL}/project_detail`, {
    headers: {
      "Accept-Language": locale,
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  });
  return res.json();
};
