import React, { useEffect, useState } from "react";
import styles from "./ProjectDetailsPage.module.scss";
import DownIcon from "./components/SVGs/DownIcon";
import Carousel from "./components/Carousel";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import assets from "./assets";
import ProjectCard from "./components/ProjectCard";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProject } from "./utils/getProjects";
import LoadingScreen from "./components/LoadingScreen";

export default function ProjectDetailsPage() {
  const { locale, id } = useParams();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [data, setData] = useState(null);

  const exploreMoreProjectsSlider = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setProject(null);
      const res = await getProject(locale, id);
      setProject(res.project_detail);
      setData(res.company_info[0]);
      setLoading(false);
    }
    startFetch();
  }, [locale]);
  if(loading){
    return <LoadingScreen/>
  }
  return (
    <>
      <Navbar />
      <main>
        <div className={styles.projectDetails}>
          <a href={`/${locale}/projects`}>
            <span>
              <DownIcon />
            </span>
            {t("projects_details.back_to_all_projects")}
          </a>

          <h1>{project?.title}</h1>

          <div className={styles.info}>
            <div>
              <h3>{t("projects_details.location")}</h3>
              <p>{project?.location}</p>
            </div>
            <div>
              <h3>{t("projects_details.completed")}</h3>
              <p>{project?.completed}</p>
            </div>
            <div>
              <h3>{t("projects_details.project_type")}</h3>
              <p>{project?.type.title}</p>
            </div>
          </div>
          <div className={styles.carouselWrapper}>
            <Carousel>
              {project?.images.map((image, index) => {
                return (
                  <div className={styles.imgWrapper} key={index}>
                    <img src={image.image} alt="" />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div className={styles.withoutBg}>
          <article>
            <h5>{project?.description}</h5>
            <p>{project?.body}</p>
          </article>
          <div className={styles.moreProjects}>
            <h5>{t("projects_details.explore_more")}</h5>
            <Carousel sliderSettings={exploreMoreProjectsSlider}>
              {project?.explore_more_projects.map((project) => {
                return (
                  <div key={project.id} className={styles.slideWrapper}>
                    <ProjectCard
                      project={{
                        image: project.thumbnail,
                        title: project.title,
                        description: project.body,
                        id: project.id,
                      }}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </main>
      <Footer data={data} />
    </>
  );
}
