import "./globals.scss";
import { Routes, Route, Navigate, useParams } from "react-router-dom";
import HomePage from "./homePage";
import AboutPage from "./aboutPage";
import { useTranslation } from "react-i18next";
import NotFound from "./notFoundPage";
import { HelmetProvider } from "react-helmet-async";
import SEO from "./components/SEO";
import ProjectsPage from "./projectsPage";
import ProjectDetailsPage from "./ProjectDetailsPage";
import { useEffect } from "react";

function App() {
  const { i18n } = useTranslation();
  document.documentElement.setAttribute("lang", i18n.language);
  document.body.dir = i18n.language === "ar" ? "rtl" : "ltr";

  const { locale } = useParams();

  useEffect(() => {
    i18n.changeLanguage(locale);
    document.body.dir = locale === "ar" ? "rtl" : "ltr";
  }, [locale]);

  return (
    <HelmetProvider>
      <SEO title={"HIG Steel"} />
      <Routes>
        <Route path="/" element={<Navigate to={"/en"} />} exact />
        <Route path="/:locale" element={<HomePage />} exact />
        <Route path="/:locale/about" element={<AboutPage />} exact />
        <Route path="/:locale/projects" element={<ProjectsPage />} exact />
        <Route
          path="/:locale/projects/:id"
          element={<ProjectDetailsPage />}
          exact
        />
        <Route path="/:locale/*" element={<NotFound />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
