import React, { useEffect, useState } from "react";
import styles from "./projectsPage.module.scss";
import ProjectsFilters from "./components/ProjectsFilters";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getProjectFilters, getProjects } from "./utils/getProjects";
import { getHome } from "./utils/getHome";
import LoadingScreen from "./components/LoadingScreen";

export default function ProjectsPage() {
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [filters, setFilters] = useState(null);
  const [data, setData] = useState(null);
  const [activeFilters, setActiveFilters] = useState(null);
  const [projects, setProjects] = useState(null);
  const { locale } = useParams();
  const { i18n, t } = useTranslation();
  const [searchParams] = useSearchParams();

  const pageNum =
    Number(searchParams.get("page_num")) === 0
      ? 1
      : Number(searchParams.get("page_num"));

  useEffect(() => {
    setLoading(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setFilters(null);
      const res = await getProjectFilters(locale);
      const projectsRes = await getProjects(locale, activeFilters, pageNum);
      const data = await getHome(locale);
      setFilters(res);
      setProjects(projectsRes);
      setData(data.company_info[0]);
      setLoading(false);
    }
    startFetch();
  }, [locale]);

  useEffect(() => {
    setLoadingProducts(true);
    if (locale) i18n.changeLanguage(locale);
    async function startFetch() {
      setProjects(null);
      const projectsRes = await getProjects(locale, activeFilters, pageNum);
      setProjects(projectsRes);
      setLoadingProducts(false);
    }
    startFetch();
  }, [activeFilters, pageNum]);

  if (loading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Navbar />
      <main className={styles.ourProjects}>
        <h1>{t("projects.title")}</h1>
        <ProjectsFilters
          loadingProducts={loadingProducts}
          projects={projects}
          filters={filters}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
      </main>
      <Footer data={data} />
    </>
  );
}
