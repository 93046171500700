import React from "react";
import styles from "./WhatWeDo.module.scss";
import {useTranslation} from "react-i18next"
function WhatWeDo({ content }) {
  // STATES
  // STORE STATES
  // HOOKS
  const {t} = useTranslation();

  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.what}>
      <h2 className={styles.whatTitle}>{t("home.whatwedo")}</h2>
      <div className={styles.whatCardContainer}>
        {content?.map((card, index) => {
          return (
            <div key={index} className={styles.whatCard}>
              <img src={card.image} width={70} height={70} alt="" />
              <h3 className={styles.whatCardTitle}>{card.title}</h3>
              <p className={styles.whatCardBody}>{card.body}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default WhatWeDo;
