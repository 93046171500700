import React from "react";
import styles from "./ProjectsSection.module.scss";
import Carousel from "../Carousel";
import ProjectCard from "../ProjectCard";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

export default function ProjectsSection({ projects }) {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { locale } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.projectsSection}>
      <div className={`container ${styles.header}`}>
        <h2>{t("home.explore")}</h2>
        {projects?.length > 4 && (
          <button onClick={() => navigate(`/${locale}/projects`)}>
            {t("home.seeall")}
          </button>
        )}
      </div>
      <div className={styles.carouselWrapper}>
        <Carousel sliderSettings={sliderSettings}>
          {projects?.map((project) => {
            return (
              <ProjectCard
                key={project.id}
                project={{
                  id: project.id,
                  image: project.thumbnail,
                  title: project.title,
                  description: project.body,
                }}
              />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}
