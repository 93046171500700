import React, { useRef, useState } from "react";
import styles from "./index.module.scss";

import assets from "../../assets";

function Video({ src, autoplay, muted, className, poster }) {
  // STATES
  const [play, setPlay] = useState(autoplay ? true : false);
  const [showPoster, setShowPoster] = useState(autoplay ? false : true);
  const videoRef = useRef(null);
  // STORE STATES
  // HOOKS
  // METHODS
  const playPause = (e) => {
    e.stopPropagation();
    if (videoRef.current.paused) {
      setShowPoster(false);
      setPlay(true);
      videoRef.current.play();
    } else {
      setShowPoster(true);
      setPlay(false);
      videoRef.current.pause();
    }
  };
  // EFFECTS
  // RENDER
  return (
    <div className={`${styles.videoContainer} ${className}`}>
      <video
        ref={videoRef}
        className={`${styles.video} ${showPoster ? styles.hide : ""}`}
        loop={true}
        muted={muted || true}
        autoPlay={autoplay || false}
      >
        <source
          src={`${
            src ? src : "https://static.pexels.com/lib/videos/free-videos.mp4"
          }`}
          type="video/mp4"
        />
      </video>

      {showPoster && poster && (
        <>
          <div
            style={{ backgroundImage: `url(${poster})` }}
            className={styles.posterLeft}
          ></div>
          <div
            style={{ backgroundImage: `url(${poster})` }}
            className={styles.posterRight}
          ></div>
        </>
      )}
      <div onClick={playPause} className={styles.videoControls}>
        {!play && (
          <div className={styles.videoControlsContainer}>
            <button onClick={playPause} className={styles.videoPlayBtn}>
              <img src={assets.icons.playIcon} alt="play icon" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
export default Video;
