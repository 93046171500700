import React from "react";

function Logo() {
  return (
    <svg
      width="208"
      height="40"
      viewBox="0 0 208 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group">
        <g id="Group_2">
          <g id="Group_3">
            <path
              id="Vector"
              d="M45.5863 28.6373C45.5863 27.1381 46.7608 26.0137 48.3111 26.0137C49.1098 26.0137 49.8144 26.2948 50.2842 26.8101L49.8144 27.2786C49.4386 26.857 48.9218 26.6696 48.3581 26.6696C47.1836 26.6696 46.338 27.5129 46.338 28.6373C46.338 29.7617 47.1836 30.605 48.3581 30.605C48.9218 30.605 49.3916 30.4176 49.8144 29.996L50.2842 30.4645C49.8144 30.9798 49.1098 31.2609 48.3111 31.2609C46.7138 31.2609 45.5863 30.1834 45.5863 28.6373Z"
              fill="currentcolor"
            />
            <path
              id="Vector_2"
              d="M53.9017 28.6373C53.9017 27.1381 55.0762 26.0137 56.6265 26.0137C58.1769 26.0137 59.3513 27.1381 59.3513 28.6373C59.3513 30.1365 58.1769 31.2609 56.6265 31.2609C55.0762 31.2609 53.9017 30.1365 53.9017 28.6373ZM58.6467 28.6373C58.6467 27.5129 57.801 26.6696 56.6735 26.6696C55.499 26.6696 54.6534 27.5129 54.6534 28.6373C54.6534 29.7617 55.499 30.605 56.6735 30.605C57.801 30.605 58.6467 29.7617 58.6467 28.6373Z"
              fill="currentcolor"
            />
            <path
              id="Vector_3"
              d="M68.0891 26.1074V31.2141H67.4784L64.3778 27.4192V31.2141H63.6261V26.1074H64.2368L67.3375 29.9023V26.1074H68.0891Z"
              fill="currentcolor"
            />
            <path
              id="Vector_4"
              d="M72.3177 30.6051L72.5996 30.0428C72.9755 30.3708 73.6332 30.6519 74.2909 30.6519C75.1835 30.6519 75.5593 30.324 75.5593 29.8554C75.5593 28.5905 72.4587 29.3869 72.4587 27.5129C72.4587 26.7165 73.0694 26.0605 74.4318 26.0605C75.0426 26.0605 75.6533 26.2011 76.0761 26.5291L75.8412 27.0913C75.3714 26.8102 74.9016 26.6696 74.4318 26.6696C73.5392 26.6696 73.1634 27.0444 73.1634 27.4661C73.1634 28.731 76.264 27.9346 76.264 29.8086C76.264 30.6051 75.6533 31.261 74.2909 31.261C73.4922 31.261 72.7406 30.9799 72.3177 30.6051Z"
              fill="currentcolor"
            />
            <path
              id="Vector_5"
              d="M81.4315 26.7165H79.6932V26.0605H83.9214V26.7165H82.1831V31.2141H81.4784V26.7165H81.4315Z"
              fill="currentcolor"
            />
            <path
              id="Vector_6"
              d="M91.3438 31.2141L90.2163 29.6212C90.1223 29.6212 89.9814 29.6212 89.8874 29.6212H88.619V31.1673H87.8673V26.1074H89.8874C91.2028 26.1074 92.0485 26.7633 92.0485 27.8877C92.0485 28.6842 91.6257 29.2464 90.921 29.5275L92.1424 31.261H91.3438V31.2141ZM91.2968 27.8877C91.2968 27.1381 90.827 26.7165 89.8874 26.7165H88.619V29.0122H89.8874C90.827 29.0122 91.2968 28.5905 91.2968 27.8877Z"
              fill="currentcolor"
            />
            <path
              id="Vector_7"
              d="M96.3712 29.0122V26.1074H97.1229V29.0122C97.1229 30.1366 97.6397 30.6519 98.5792 30.6519C99.5188 30.6519 100.036 30.1366 100.036 29.0122V26.1074H100.74V29.0122C100.74 30.5114 99.9416 31.261 98.5792 31.261C97.1699 31.261 96.3712 30.4645 96.3712 29.0122Z"
              fill="currentcolor"
            />
            <path
              id="Vector_8"
              d="M104.922 28.6373C104.922 27.1381 106.096 26.0137 107.646 26.0137C108.445 26.0137 109.15 26.2948 109.619 26.8101L109.15 27.2786C108.774 26.857 108.257 26.6696 107.693 26.6696C106.519 26.6696 105.673 27.5129 105.673 28.6373C105.673 29.7617 106.519 30.605 107.693 30.605C108.257 30.605 108.727 30.4176 109.15 29.996L109.619 30.4645C109.15 30.9798 108.445 31.2609 107.646 31.2609C106.096 31.2609 104.922 30.1834 104.922 28.6373Z"
              fill="currentcolor"
            />
            <path
              id="Vector_9"
              d="M114.786 26.7165H113.048V26.0605H117.276V26.7165H115.538V31.2141H114.833V26.7165H114.786Z"
              fill="currentcolor"
            />
            <path
              id="Vector_10"
              d="M121.224 26.1074H121.975V31.2141H121.224V26.1074Z"
              fill="currentcolor"
            />
            <path
              id="Vector_11"
              d="M126.25 28.6373C126.25 27.1381 127.425 26.0137 128.975 26.0137C130.525 26.0137 131.7 27.1381 131.7 28.6373C131.7 30.1365 130.525 31.2609 128.975 31.2609C127.425 31.2609 126.25 30.1365 126.25 28.6373ZM130.995 28.6373C130.995 27.5129 130.15 26.6696 129.022 26.6696C127.848 26.6696 127.002 27.5129 127.002 28.6373C127.002 29.7617 127.848 30.605 129.022 30.605C130.15 30.605 130.995 29.7617 130.995 28.6373Z"
              fill="currentcolor"
            />
            <path
              id="Vector_12"
              d="M140.438 26.1074V31.2141H139.827L136.726 27.4192V31.2141H135.974V26.1074H136.585L139.686 29.9023V26.1074H140.438Z"
              fill="currentcolor"
            />
            <path
              id="Vector_13"
              d="M149.834 28.6373C149.834 27.1381 151.008 26.0137 152.559 26.0137C153.357 26.0137 154.062 26.2948 154.532 26.8101L154.062 27.2786C153.686 26.857 153.169 26.6696 152.606 26.6696C151.431 26.6696 150.585 27.5129 150.585 28.6373C150.585 29.7617 151.431 30.605 152.606 30.605C153.169 30.605 153.639 30.4176 154.062 29.996L154.532 30.4645C154.062 30.9798 153.357 31.2609 152.559 31.2609C151.008 31.2609 149.834 30.1834 149.834 28.6373Z"
              fill="currentcolor"
            />
            <path
              id="Vector_14"
              d="M158.196 28.6373C158.196 27.1381 159.37 26.0137 160.92 26.0137C162.471 26.0137 163.645 27.1381 163.645 28.6373C163.645 30.1365 162.471 31.2609 160.92 31.2609C159.37 31.2609 158.196 30.1365 158.196 28.6373ZM162.94 28.6373C162.94 27.5129 162.095 26.6696 160.967 26.6696C159.793 26.6696 158.947 27.5129 158.947 28.6373C158.947 29.7617 159.793 30.605 160.967 30.605C162.048 30.605 162.94 29.7617 162.94 28.6373Z"
              fill="currentcolor"
            />
            <path
              id="Vector_15"
              d="M172.713 31.2141V27.4661L170.88 30.5582H170.552L168.719 27.4661V31.1673H168.015V26.1074H168.625L170.786 29.7149L172.901 26.1074H173.511V31.2141H172.713Z"
              fill="currentcolor"
            />
            <path
              id="Vector_16"
              d="M182.249 27.8877C182.249 29.0122 181.45 29.6681 180.088 29.6681H178.82V31.2141H178.068V26.1074H180.088C181.45 26.1074 182.249 26.7633 182.249 27.8877ZM181.497 27.8877C181.497 27.1381 181.028 26.7165 180.088 26.7165H178.82V29.0122H180.088C181.028 29.0122 181.497 28.5905 181.497 27.8877Z"
              fill="currentcolor"
            />
            <path
              id="Vector_17"
              d="M189.484 29.9492H186.76L186.196 31.2141H185.444L187.746 26.1074H188.451L190.8 31.2141H190.048L189.484 29.9492ZM189.25 29.3401L188.122 26.8102L186.995 29.3401H189.25Z"
              fill="currentcolor"
            />
            <path
              id="Vector_18"
              d="M199.162 26.1074V31.2141H198.551L195.45 27.4192V31.2141H194.699V26.1074H195.309L198.41 29.9023V26.1074H199.162Z"
              fill="currentcolor"
            />
            <path
              id="Vector_19"
              d="M205.833 29.4338V31.2141H205.128V29.4338L203.108 26.1074H203.906L205.551 28.8248L207.195 26.1074H207.9L205.833 29.4338Z"
              fill="currentcolor"
            />
          </g>
          <g id="Group_4">
            <path
              id="Vector_20"
              d="M57.5647 9.84961V22.4524H54.652V17.2989H48.9205V22.4524H46.0078V9.84961H48.9205V14.8158H54.652V9.84961H57.5647Z"
              fill="currentcolor"
            />
            <path
              id="Vector_21"
              d="M69.4037 19.7351H63.5313L62.4038 22.4524H59.3971L65.0346 9.84961H67.9004L73.5379 22.4524H70.4842L69.4037 19.7351ZM68.4641 17.5331L66.444 12.7075L64.4239 17.5331H68.4641Z"
              fill="currentcolor"
            />
            <path
              id="Vector_22"
              d="M86.9737 18.9855C86.9737 21.1406 85.2354 22.4056 81.9469 22.4056H75.4167V9.84961H81.5711C84.7187 9.84961 86.3629 11.1614 86.3629 13.1292C86.3629 14.3941 85.7052 15.378 84.6717 15.8933C86.0811 16.3618 86.9737 17.4394 86.9737 18.9855ZM78.3295 12.0516V15.0032H81.2422C82.6516 15.0032 83.4502 14.4878 83.4502 13.504C83.4502 12.5201 82.6516 12.0516 81.2422 12.0516H78.3295ZM84.061 18.7044C84.061 17.6268 83.2623 17.1583 81.712 17.1583H78.2825V20.2504H81.712C83.2623 20.2504 84.061 19.7819 84.061 18.7044Z"
              fill="currentcolor"
            />
            <path
              id="Vector_23"
              d="M98.0613 19.7351H92.1889L91.0614 22.4524H88.0547L93.6922 9.84961H96.558L102.195 22.4524H99.1418L98.0613 19.7351ZM97.1217 17.5331L95.1016 12.7075L93.0815 17.5331H97.1217Z"
              fill="currentcolor"
            />
            <path
              id="Vector_24"
              d="M103.088 21.1873L104.075 18.9853C105.155 19.7818 106.705 20.2972 108.256 20.2972C109.994 20.2972 110.699 19.7349 110.699 18.9385C110.699 16.596 103.37 18.1889 103.37 13.5038C103.37 11.3487 105.108 9.56836 108.679 9.56836C110.276 9.56836 111.873 9.94316 113.048 10.6928L112.155 12.8948C110.981 12.2388 109.759 11.9109 108.679 11.9109C106.94 11.9109 106.283 12.5668 106.283 13.3633C106.283 15.7058 113.611 14.066 113.611 18.7042C113.611 20.8125 111.873 22.5928 108.256 22.5928C106.236 22.6397 104.216 22.0775 103.088 21.1873Z"
              fill="currentcolor"
            />
            <path
              id="Vector_25"
              d="M127.657 9.84961V22.4524H124.744V17.2989H119.013V22.4524H116.1V9.84961H119.013V14.8158H124.744V9.84961H127.657Z"
              fill="currentcolor"
            />
            <path
              id="Vector_26"
              d="M137.242 18.0016V22.4524H134.329V17.9548L129.443 9.84961H132.544L135.926 15.4248L139.309 9.84961H142.175L137.242 18.0016Z"
              fill="currentcolor"
            />
            <path
              id="Vector_27"
              d="M148.564 21.1873L149.551 18.9853C150.631 19.7818 152.181 20.2972 153.732 20.2972C155.47 20.2972 156.175 19.7349 156.175 18.9385C156.175 16.596 148.846 18.1889 148.846 13.5038C148.846 11.3487 150.584 9.56836 154.155 9.56836C155.752 9.56836 157.349 9.94316 158.524 10.6928L157.631 12.8948C156.456 12.2388 155.235 11.9109 154.155 11.9109C152.416 11.9109 151.759 12.5668 151.759 13.3633C151.759 15.7058 159.087 14.066 159.087 18.7042C159.087 20.8125 157.349 22.5928 153.732 22.5928C151.665 22.6397 149.644 22.0775 148.564 21.1873Z"
              fill="currentcolor"
            />
            <path
              id="Vector_28"
              d="M164.02 12.239H159.98V9.84961H170.973V12.239H166.933V22.4524H164.02V12.239Z"
              fill="currentcolor"
            />
            <path
              id="Vector_29"
              d="M182.812 20.1099V22.4524H173.04V9.84961H182.577V12.1921H175.953V14.9095H181.825V17.1583H175.953V20.063H182.812V20.1099Z"
              fill="currentcolor"
            />
            <path
              id="Vector_30"
              d="M195.449 20.1099V22.4524H185.677V9.84961H195.214V12.1921H188.59V14.9095H194.462V17.1583H188.59V20.063H195.449V20.1099Z"
              fill="currentcolor"
            />
            <path
              id="Vector_31"
              d="M198.314 9.84961H201.227V20.063H207.569V22.4524H198.314V9.84961Z"
              fill="currentcolor"
            />
          </g>
          <path
            id="Vector_32"
            d="M10.398 12.7994L10.5859 30.7432L15.7536 28.1664L15.7066 22.8723L22.9884 20.436H23.0354L23.1294 30.6495L27.4985 28.0727L27.3105 11.4876L22.9415 11.5344L19.324 13.5959L22.9884 13.549L23.0354 19.5927L15.6597 19.6864L15.5657 10.1289L9.50535 10.1758L5.88794 12.284L10.351 12.2372C10.398 12.4246 10.398 12.612 10.398 12.7994Z"
            fill="url(#paint0_linear_248_887)"
          />
          <path
            id="Vector_33"
            d="M32.525 0.947266H4.94809H0.0152588V5.86658V34.6329V39.5522H4.94809H26.3237H28.3908L29.8472 38.0998L36.0015 31.9624L37.4578 30.51V28.4486V5.86658V0.947266H32.525ZM32.525 28.5423L26.3707 34.6329H4.94809V5.86658H32.478V28.5423H32.525Z"
            fill="url(#paint1_linear_248_887)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_248_887"
          x1="21.2329"
          y1="27.7902"
          x2="9.45747"
          y2="5.42127"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0154639" stopColor="#00A0DE" />
          <stop offset="1" stopColor="#4A7FA7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_248_887"
          x1="26.602"
          y1="34.0221"
          x2="3.60621"
          y2="-6.39309"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0154639" stopColor="#00A0DE" />
          <stop offset="1" stopColor="#4A7FA7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo;
