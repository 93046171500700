import React from "react";
import styles from "./OurApproach.module.scss";
import Approach from "./Approach";
import { useTranslation } from "react-i18next";

function OurApproach({ approaches }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <section className={styles.ourApproach}>
      <h2 className={styles.ourApproachTitle}>{t("about.approach")}</h2>
      <div className={styles.ourApproachContainer}>
        {approaches?.map((card, index) => {
          return <Approach approach={card} index={index} key={index} />;
        })}
      </div>
    </section>
  );
}
export default OurApproach;
