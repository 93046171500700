import React from 'react'
import styles from "./index.module.scss";

export default function LoadingScreen() {
  return (
    <div className={styles.loadingScreen}>
        <div className={styles.ldsDualRing}></div>
    </div>
  )
}
