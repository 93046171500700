import React from "react";
import styles from "./NavbarList.module.scss";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavbarList({ light }) {
  // HOOKS
  const { locale } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // VARIABLES
  const tabs = [
    {
      title: t("common.about"),
      link: "about",
    },
    {
      title: t("common.projects"),
      link: "projects",
    },
    {
      title: t("home.contactus"),
      link: "#contactus",
    },
  ];

  // STATES
  // STORE STATES

  // METHODS
  // EFFECTS
  // RENDER
  return (
    <ul className={`${styles.navbarList} ${light ? styles.light : undefined}`}>
      {tabs.map((tab, index) => (
        <li
          key={index}
          className={`${styles.navbarListItem} ${
            pathname.split("/")[2] === tab.link
              ? styles.navbarListItemActive
              : undefined
          }`}
        >
          {
            <a
              href={`/${locale}${
                tab.link === "#contactus" ? tab.link : `/${tab.link}`
              }`}
            >
              {tab.title}
            </a>
          }
        </li>
      ))}
    </ul>
  );
}
export default NavbarList;
