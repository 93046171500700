import React from "react";
import styles from "./index.module.scss";
import { useForm } from "react-hook-form";
import TextInput from "./TextInput/TextInput";
import { useTranslation } from "react-i18next";

function HigForm({ message, fields, onSubmit }) {
  const { t } = useTranslation();
  const defaultValues = {};

  for (const field of fields) {
    defaultValues[field.fieldName] = "";
  }

  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const watchingResume = watch("resume");
  return (
    <div>
      <form
        onSubmit={handleSubmit((data) => onSubmit(data, reset))}
        className={styles.form}
      >
        <div className={styles.inputsWrapper}>
          {/* mapping through the fields array */}
          {fields.map((field) => (
            <TextInput
              key={field.fieldName}
              {...field}
              {...register(field.fieldName, field.validations)}
              watchingResume={watchingResume}
              error={errors[field.fieldName]?.message}
            />
          ))}
        </div>
        <div className={styles.formControl}>
          <button type="submit" className={styles.submitBtn}>
            {t("labels.send")}
          </button>
        </div>
      </form>
      {message && (
        <p
          className={`${styles.message} ${
            message.success ? styles.success : styles.failure
          }`}
        >
          {message.text}
        </p>
      )}
    </div>
  );
}

export default HigForm;
