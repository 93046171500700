import React, { useState } from "react";
import styles from "./index.module.scss";
import HigForm from "../HigForm/HigForm";
import { submitContact } from "../../utils/submitContact";
import { useTranslation } from "react-i18next";

function FormSection({ children, content, formFields, page }) {
  const [message, setMessage] = useState(null);
  const { t } = useTranslation();
  const onSubmit = async (data, reset) => {
    let status = await submitContact(data);
    reset();
    if (status.toString().startsWith("2")) {
      setMessage({
        success: true,
        text: t("labels.form_success"),
      });
    } else {
      setMessage({
        success: false,
        text: t("labels.form_failure"),
      });
    }
    setTimeout(() => {
      setMessage(null);
    }, 3000);
  };
  return (
    <div className={styles.formSectionWrapper}>
      <div className={styles.col}>
        <h2>{content.title}</h2>
        <p>{content.description}</p>
        <HigForm message={message} fields={formFields} onSubmit={onSubmit} />
      </div>
      <div className={styles.col}>{children}</div>
    </div>
  );
}

export default FormSection;
