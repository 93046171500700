import React from "react";
import Logo from "../SVGs/Logo";
import LanguageSwitcher from "../LanguageSwitcher";
import styles from "./Navbar.module.scss";
import NavbarList from "./NavbarList";

import HamburgerMenu from "./HamburgerMenu";

function Navbar({ light }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <>
      <nav className={`${styles.navbar} ${light ? styles.light : undefined}`}>
        <NavbarList light={light} />
        <a className={styles.navbarLogo} href={"/"}>
          <Logo />
        </a>
        <div className={styles.navbarContent}>
          <LanguageSwitcher />
          <p className={styles.navbarEmail}>Contact@alhabashy.com</p>
        </div>
      </nav>
      <HamburgerMenu light={light} />
    </>
  );
}
export default Navbar;
