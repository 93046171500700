import React from "react";
import styles from "./About.module.scss";
import Video from "../Video";
import { useTranslation } from "react-i18next";

function About({ about, info }) {
  // STATES
  // STORE STATES
  // HOOKS
  const { t } = useTranslation();
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <section className={styles.about}>
      <p className={styles.aboutText}>{t("home.aboutHig")}</p>
      <div className={styles.aboutContainer}>
        <div className={styles.aboutContent}>
          <h2 className={styles.aboutTitle}>
            <span className={styles.aboutTitleNo}>
              {info?.years_of_experience}
            </span>{" "}
            {t("home.moreAbout")}
          </h2>
          <p className={styles.aboutBody}>{about?.body}</p>
          <button className={styles.aboutButton}>{t("home.moreAbout")}</button>
        </div>
        <div className={styles.aboutVideo}>
          <Video
            poster={about?.thumbnail_video}
            autoplay={false}
            src={about?.video}
            className={styles.specialVideo}
          />
        </div>
      </div>
    </section>
  );
}
export default About;
