import React from "react";
import styles from "./HeroSection.module.scss";
import Carousel from "../Carousel";
import HeroSlide from "./HeroSlide";
import { useTranslation } from "react-i18next";

export default function HeroSection({slides}) {
  const { t } = useTranslation();
  return (
    <div className={styles.heroSection}>
      <Carousel end={true} whiteDot={true} sliderSettings={{ autoplay: true, arrows: false}}>
        {slides?.map((slide, index) => {
          return <HeroSlide
            key={index}
            img={slide.image}
            headerText={slide.title}
            description={slide.body}
            explore={t("home.exploreMore")}
          />
        })}
      </Carousel>
    </div>
  );
}
