import React from "react";
import styles from "./Approach.module.scss";

function Approach({ approach, index }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <div className={styles.card}>
      <p className={styles.cardNumber}>{`0${index + 1}`}</p>
      <h3 className={styles.cardTitle}>{approach.title}</h3>
      <p className={styles.cardBody}>{approach.body}</p>
    </div>
  );
}
export default Approach;
