import React from "react";
import styles from "./index.module.scss";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
export default function PaginationDot({ active, pageNum }) {
  const navigate = useNavigate();
  const { locale } = useParams();
  const [searchParams] = useSearchParams();
  const handlePaging = () => {
    if (!active) {
      navigate(`/${locale}/projects?page_num=${pageNum}`);
    }
  };
  return (
    <span
      className={`${styles.dot} ${
        active
          ? styles.active
          : ""
      }`}
      onClick={handlePaging}
    >
      <span></span>
    </span>
  );
}
