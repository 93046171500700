import React from "react";
import Logo from "../SVGs/Logo";
import styles from "./Footer.module.scss";
import assets from "../../assets";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer({ data }) {
  const { locale } = useParams();
  const { t } = useTranslation();

  const links = [
    {
      title: t("common.about"),
      link: "/about",
    },
    {
      title: t("common.projects"),
      link: "/projects",
    },
  ];
  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.info}`}>
        <div className={styles.col}>
          <Logo />
          <p>{data?.address}</p>
        </div>
        <div className={styles.col}>
          <ul>
            {links.map((link, index) => {
              return (
                <li key={index}>
                  <a href={`/${locale}${link.link}`}>{link.title}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.col}>

          <div className={styles.contactPhone}>
            <p className={styles.phone}>
              <span>{t("labels.phonenumber")} :</span> {data?.phone}</p>
            {data?.phone2 &&
              <>
                <span> - </span>
                <p className={styles.phone}>{data.phone2}</p>
              </>}
          </div>
          <p>
            <span>{t("labels.email")} :</span> {data?.email}
          </p>
        </div>
      </div>
      <div className={`container ${styles.copyrights}`}>
        <p>
          © {new Date().getFullYear()}, {t("common.copyrights")}
        </p>
        <div className={styles.socialMedia}>
          <a
            href={data?.facebook}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            <img src={assets.icons.facebookIcon} alt="out facebook page" />
          </a>
          <a
            href={data?.linkedin}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            <img src={assets.icons.linkedInIcon} alt="out linkedin page" />
          </a>

          <a
            href={data?.instagram}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            <img src={assets.icons.instgramIcon} alt="out instgram page" />
          </a>
        </div>
      </div>
    </footer >
  );
}
