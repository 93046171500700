import React from "react";
import styles from "./ProjectCard.module.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProjectCard({ project, radius }) {
  const { locale } = useParams();
  const { t } = useTranslation();
  return (
    <div className={styles.projectCard}>
      <a href={`/${locale}/projects/${project.id}`}>
        <img src={project.image} alt={project.title} />
        <div className={styles.explore}>{t("explore")}</div>
        <div className={styles.projectDetails}>
          <h4>{project.title}</h4>
          <p>{project.description}</p>
        </div>
      </a>
    </div>
  );
}
