export const submitContact = async (data) => {
  const res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/contact_us_submit`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return res.status;
};
