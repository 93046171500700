import React from "react";
import styles from "./WhoWeAre.module.scss";
import Video from "../Video";

function WhoWeAre({ content }) {
  // STATES
  // STORE STATES
  // HOOKS
  // METHODS
  // EFFECTS
  // RENDER
  return (
    <section className={styles.whoWeAre}>
      <div className={styles.whoWeAreContent}>
        <h2 className={styles.whoWeAreTitle}>{content?.title}</h2>
        <p className={styles.whoWeAreBody}>{content?.body}</p>
      </div>
      <Video
        className={styles.whoWeAreImage}
        autoplay={false}
        src={content?.video}
        poster={content?.poster}
        alt="hero"
      />
      <div className={styles.whoWeAreSecondaryContent}>
        <h3 className={styles.whoWeAreSecondaryTitle}>
          {content?.story_title}
        </h3>
        <p className={styles.whoWeAreSecondaryBody}>{content?.story_body}</p>
      </div>
    </section>
  );
}
export default WhoWeAre;
