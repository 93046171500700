import React, { useState } from "react";
import assets from "../../assets";
import styles from "./index.module.scss";
import ProjectCard from "../ProjectCard";
import PaginationDot from "../PaginationDot";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../Loading";

export default function ProjectsFilters({
  filters,
  activeFilters,
  projects,
  loadingProducts,
  setActiveFilters,
}) {
  const [showFilters, setShowFilters] = useState(false);
  const setProjectType = (type) => {
    setActiveFilters((prev) => {
      return { ...prev, type };
    });
  };
  const { locale } = useParams();
  const animatedComponents = makeAnimated();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.projectsFilters}>
      <div className={styles.filtersNav}>
        <div className={styles.categories}>
          <span
            onClick={() => {
              setProjectType("all");
              navigate(`/${locale}/projects?page_num=1`);
            }}
          >
            {t("projects.showall")}
          </span>
          {filters?.project_type.map((type) => {
            return (
              <span
                onClick={() => {
                  setProjectType(type.id);
                  navigate(`/${locale}/projects?page_num=1`);
                }}
                className={
                  activeFilters?.type === type.id ? styles.activeType : ""
                }
                key={type.id}
              >
                {type.title}
              </span>
            );
          })}
        </div>
        <button onClick={() => setShowFilters((prev) => !prev)}>
          <img
            src={assets.icons.filterIcon}
            alt="Filter"
            width={22}
            height={16}
          />
          {t("filter")}
        </button>
      </div>
      {showFilters ? (
        <div className={styles.filters}>
          <Select
            isMulti
            options={filters?.project_service.map((service) => {
              return { value: service.id, label: service.title };
            })}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isRtl={locale === "ar"}
            onChange={(newVal) => {
              setActiveFilters((prev) => {
                return { ...prev, service: newVal };
              });
              navigate(`/${locale}/projects?page_num=1`);
            }}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? "#103649"
                  : "rgba(16, 54, 73, 0.4)",
                borderRadius: "1.5rem",
                padding: "1rem",
                fontSize: "1.8rem",
                minWidth: "fit-content",
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1.8rem",
                borderRadius: "1rem",
                overflow: "hidden",
                padding: "0px",
                zIndex: "1000",
              }),
              menuList: (baseStyles) => ({
                ...baseStyles,
                padding: "0px",
              }),
              option: (baseStyles) => ({
                ...baseStyles,
                cursor: "pointer !important",
              }),
            }}
            placeholder={t("filter_by_service")}
            defaultValue={activeFilters?.service}
          />
          <Select
            isMulti
            defaultValue={activeFilters?.area}
            options={filters?.project_area.map((area) => {
              return { value: area.id, label: area.title };
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: state.isFocused
                  ? "#103649"
                  : "rgba(16, 54, 73, 0.4)",
                borderRadius: "1.5rem",
                padding: "1rem",
                fontSize: "1.8rem",
                minWidth: "fit-content",
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                fontSize: "1.8rem",
                borderRadius: "1rem",
                overflow: "hidden",
                padding: "0px",
                zIndex: "1000",
              }),
              menuList: (baseStyles) => ({
                ...baseStyles,
                padding: "0px",
              }),
              option: (baseStyles) => ({
                ...baseStyles,
                cursor: "pointer !important",
              }),
            }}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isRtl={locale === "ar"}
            onChange={(newVal) =>
              setActiveFilters((prev) => {
                return { ...prev, area: newVal };
              })
            }
            placeholder={t("filter_by_area")}
          />
        </div>
      ) : null}

      {loadingProducts && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      {!loadingProducts && projects?.results.results.length > 0 && (
        <>
          <div className={styles.projects}>
            {projects?.results?.results.map((project) => {
              return (
                <ProjectCard
                  key={project.id}
                  project={{
                    image: project.thumbnail,
                    title: project.title,
                    description: project.body,
                    id: project.id,
                  }}
                />
              );
            })}
          </div>
          <div className={styles.pagination}>
            {new Array(Math.ceil(projects?.count / 12) || 1)
              .fill(0)
              .map((page, index) => (
                <PaginationDot
                  key={index}
                  active={
                    Number(searchParams.get("page_num"))
                      ? Number(searchParams.get("page_num")) === index + 1
                      : Number(searchParams.get("page_num")) === index
                  }
                  pageNum={index + 1}
                />
              ))}
          </div>
        </>
      )}
      {!loadingProducts && projects?.results.results.length === 0 && (
        <p className={styles.noProducts}>{t("noProducts")}</p>
      )}
    </div>
  );
}
